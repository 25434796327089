
#username {
 background-color:  #f3d503;
 font-family: Patua One, serif;
 font-size: 4.0vw;
}

#username_input {
  outline: none;
  border: none;
  caret-color: black;
  min-width: 30px;
  display: inline-block;
  padding-left: 5px;
  text-align: left;
}


.username_input_after {
  cursor: pointer;
  padding-left: 10px;
  transition: opacity 1s ease-out;
  opacity: 0;
  visibility: hidden;
}

.username_input_after.active {
  opacity: 1;
  visibility: visible;
  transition: opacity 1s ease-out;
}

@media screen and (min-width: 700px) {
  #username {
    font-size: 28px;
  }
}
