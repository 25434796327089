.app
{
  background-color: #f3d503;
  min-height: 100vh;
  text-align: center;
}

.app-logo
{
  height: 40vmin;
  pointer-events: none;
}


.app-header
{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
