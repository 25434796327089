.connecting-container
{
  width: 370px;
  margin: 0 auto;
  -webkit-tap-highlight-color: transparent;
}

.connecting-screen-text
{
  padding: 0 30px 10px;
}

.qr-code-scanner-video
{
  margin: 0 auto;
  height: 256px;
}

.qr-code-scan
{
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Patua One, serif;
}

.qr-code-scan img
{
  margin-right: 5px;
}

.qr-code-scanner-container
{
  background-color: white;
  border-radius: 30px;
  height: 100%;
  width: 100%;
  padding: 40px 40px;
}

.qr-code-scanner
{
  width: 256px;
  margin: 0 auto;
  overflow: hidden;
}
