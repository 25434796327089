.long-press-button {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  user-select: none;

  background-color: white;
  border-radius: 10px;
  -webkit-tap-highlight-color: transparent;

  transition-timing-function: ease-in-out;

  height: 60vw;
  width: 60vw;
  max-width: 300px;
  max-height: 300px;

  font-size: 2.5rem;
  text-transform: uppercase;
  font-weight: bold;
}

.long-press-button.pressed {
  animation: shake .6s cubic-bezier(.36,.07,.19,.97) infinite;
  background-color: #f30339;
  font-size: 2rem;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
