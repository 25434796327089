.main-screen {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  align-items: center;
  justify-content: center;
}


.main-screen__cta {
  margin: 5rem 0;
}

.main-screen__selector
{
  margin: 20px;
  position: relative;
}


@keyframes checked-radio-rotate {
    0% {
        transform: rotate(0) translateY(-4.8vw) scale(.2);
    }
    83% {
        transform: rotate(360deg) translateY(-2.5vw) scale(1);
        transform-origin: 2vw;
    }
    88% {
        transform: translateY(.6vw) scale(1);
    }
    93% {
        transform: translateY(-.9vw) scale(1);
    }
    100% {
        transform: translateY(0) scale(1);
    }
}
@keyframes unchecked-radio-rotate {
    25% {
        top: -6.5vw;
    }
    50% {
        top: 9vw;
    }
    75% {
        top: -10vw;
    }
    100% {
        top: -10vw;
        transform: scale(0);
    }
}
.main-screen__selector *, .main-screen__selector *:before, .main-screen__selector *:after {
    margin: auto;
    padding: 0;
    outline: 0;
    overflow: hidden;
    box-sizing: border-box;
}

.main-screen__selector label {
    display: inline-block;
    width: 10vw;
    height: 10vw;
    border-radius: 50%;
    margin: 0 10px;
    transition: all .2s ease-in-out;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 1.6s;
    animation-name: dot-anim;
}
.main-screen__selector .radio:checked + label {
    animation-play-state: paused;
}
.main-screen__selector label:before {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -2.8vw;
    margin-left: -2.4vw;
    width: 5vw;
    height: 5vw;
    background: white;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.117647) 0 0 .8vw 0, rgba(0, 0, 0, 0.239216) 0 .8vw .8vw 0;
    transition: all .2s;
}
.main-screen__selector label:hover:before {
    box-shadow: rgba(0, 0, 0, 0.0784314) 0 0 1vw 0, rgba(0, 0, 0, 0.239216) 0 1vw 1vw 0;
}
.main-screen__selector label:after {
    content: "";
    position: absolute;
    width: 10vw;
    height: 10vw;
    background: rgba(255, 255, 255, .5);
    border-radius: 50%;
    transform: scale(0);
    margin-left: -5vw;
    margin-top: -5vw;
    top: 50%;
}
.main-screen__selector .radio:checked + label:after {
    background: transparent;
    transition: all .5s;
    transform: scale(1);
}
/**** Radio rotate animation ****/
.main-screen__selector .radio-rotate + label {
    left: 60vw;
    background: #f30339;/*#34A853;*/
    animation-delay: .6s;
}
.main-screen__selector .radio-rotate + label:before {
    /* background: #8BC34A; */
    animation: unchecked-radio-rotate .5s both;
}
.main-screen__selector .radio-rotate:checked + label:before {
    animation-name: checked-radio-rotate;
    animation-duration: .6s;
    animation-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
    animation-fill-mode: both;
}
.main-screen__selector [type="radio"] {
    display: none;
}
