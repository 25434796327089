.footer
{
  position: fixed;
  right: 0px;
  bottom: 0px;
}

.footer-menu-container
{
  overflow: hidden;  /* Hide the element content, while height = 0 */
  height: 0;
  opacity: 0;
  transition: height 0ms .8s, opacity .8s 0ms;
  position: fixed;
  bottom: 58px;
  right: 0px;
}

.footer-menu-container.active
{
  height: auto;
  opacity: 1;
  transition: height 0ms 0ms, opacity .8s 0ms;
}

.footer-menu
{
  margin: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: Patua One, serif;
}

.footer-menu-button
{
  cursor: pointer;
  margin-left: 10px;
}

.footer-icon
{
  cursor: pointer;
  display: block;
  margin: 0px 10px 10px 10px;
}
