.connecting-container *
{
  box-sizing: border-box;
}

.card-container-wrapper
{
  position: relative;
  height: 100%;
  width: 100%;
}

.card-container
{
  height: 100%;
  width: 100%;
  cursor: pointer;
  position: relative;
  transition: .6s;
  transform: perspective(1000px);
  transform-style: preserve-3d;
}

.flip-front,
.flip-back
{
  position: relative;
  width: 100%;
  height: 100%;
  padding: 1em;
  transform: rotateY(0deg);
  background: transparent;
  backface-visibility: hidden;
  transform-style: preserve-3d;
}

.flip-front
{
  transform: rotateY(0deg);
}

.flip-back
{
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateY(-180deg);
}

.card-container.isActive:not(.istouchdevice) .flip-back,
.cardContainer.isActive.istouchdevice .flip-back
{
  transform: rotateY(0deg);
}

.card-container.isActive:not(.istouchdevice) .flip-front,
.card-container.isActive.istouchdevice .flip-front
{
  transform: rotateY(180deg);
}

.card-container.horizontal .flip-back
{
  transform: rotateX(-180deg);
}

.card-container.horizontal.isActive:not(.istouchdevice) .flip-back,
.card-container.horizontal.isActive.istouchdevice .flip-back
{
  transform: rotateX(0deg);
}

.card-container.horizontal.isActive:not(.istouchdevice) .flip-front,
.card-container.horizontal.isActive.istouchdevice .flip-front
{
  transform: rotateX(180deg);
}
